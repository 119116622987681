import { StyledContainerMd } from '@components/layout/styled';
import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

interface TabsProps {
  contentBorder?: boolean;
  className?: string;
  hostedJobs?: boolean;
  isForLocation?: boolean;
  isPartnerPage?: boolean;
  tabFontSize?: string;
  mergeBorder?: boolean;
  isIconPresent?: any;
}

export const StyleFormGroup = styled.div<{ isLabelOverlap?: boolean }>`
  margin-bottom: 1.6rem;
  position: relative;
  &.noSpace {
    margin: 0;
  }
`;

export const Description = styled.p`
  margin-bottom: 0.5rem;
`;

export const TabsWrapper = styled.ul<TabsProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  ${Mixin.VerticalCenter({ gap: ' 0 1.5rem', wrap: 'nowrap' })};

  ${({ theme: { JobDetailsStyle }, contentBorder }) =>
    JobDetailsStyle && contentBorder
      ? css`
          border-bottom: none;
        `
      : css`
          border-bottom: 0.1rem solid ${JobDetailsStyle.BdrColors.tabsWrapper};
        `}

  ${device.mobileLg} {
    gap: 0 0.5rem;
  }
  @media screen and (max-width: 336px) {
    gap: 0 0.5rem;
    justify-content: space-around;
  }
`;

export const TabContent = styled.div<TabsProps>`
  margin-bottom: 2rem;
  padding: ${({ contentBorder }) => (contentBorder ? '1.5rem 2rem' : '0')};
  ${({ theme: { JobDetailsStyle }, contentBorder }) =>
    JobDetailsStyle && contentBorder
      ? css`
          border: 0.1rem solid ${JobDetailsStyle.BdrColors.tabsWrapper};
        `
      : css`
          border: none;
        `}
  ${({ mergeBorder }) =>
    mergeBorder &&
    css`
      ${device.mobileLg} {
        margin-bottom: 0;
      }
    `}
`;

const textsize: { [key: string]: any } = {
  sm: css`
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.tabfontsm};
      `}
  `,
  md: css`
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.tabfontmd};
      `}
  `,
  lg: css`
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.tabfontlg};
      `}
  `,
};
export const Tab = styled.li<TabsProps>`
  padding: ${({ hostedJobs, isForLocation, isPartnerPage }) =>
    hostedJobs
      ? '1.2rem 2.4rem'
      : isForLocation
      ? '0.6rem 1.6rem'
      : isPartnerPage
      ? '1.2rem 2.4rem'
      : '1.2rem 0.4rem'};

  ${({ tabFontSize }) =>
    (tabFontSize && textsize[tabFontSize]) || textsize['md']};

  line-height: ${({ isPartnerPage }) => isPartnerPage && 1.2};
  ${device.mobileLg} {
    padding: ${({ hostedJobs, isForLocation, isPartnerPage }) =>
      hostedJobs
        ? '1.2rem 2.4rem'
        : isForLocation
        ? '0.6rem 1.6rem'
        : isPartnerPage
        ? '0.6rem 0.8rem'
        : '1.2rem 0.4rem'};
    font-size: ${({ hostedJobs, isPartnerPage }) =>
      (hostedJobs || isPartnerPage) && '2.0rem'};
    line-height: ${({ isPartnerPage }) => isPartnerPage && 1.2};
  }
  ${({ theme: { JobDetailsStyle }, className, isPartnerPage, isIconPresent }) =>
    JobDetailsStyle &&
    css`
      border-bottom: ${className == 'active'
        ? '0.3rem solid' + JobDetailsStyle.BdrColors.link
        : 'none'};
      button {
        display: grid;
        grid-template-columns: ${isIconPresent ? '1.8rem 1fr' : '100%'};
        column-gap: 0.8rem;
        align-items: center;
        color: ${className == 'active'
          ? JobDetailsStyle.Colors.linkActive
          : isPartnerPage
          ? JobDetailsStyle.Colors.linkDark
          : JobDetailsStyle.Colors.link};
        font-size: inherit;
        text-align: left;
        padding: 0;
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    `}

  ${device.mediumScreen} {
    cursor: pointer;
  }
`;

export const TabsContent = styled.div`
  padding: 2.4rem 1.6rem 0;
  ${device.mobileLg} {
    padding: 2.4rem 0 0;
  }
`;

export const TabLink = styled.button<TabsProps>``;

export const Icon = styled.i`
  margin-right: 0.5rem;
  width: 2rem;
`;

export const BackBtn = styled.a`
  padding-left: 2rem;
  margin-bottom: 2.6rem;
  display: inline-block;
  position: relative;
  ${({ theme: { BreadcrumbStyle } }) =>
    BreadcrumbStyle &&
    css`
      color: ${BreadcrumbStyle?.Colors?.BackBtn};
    `}
  .back-btn {
    ${Mixin.Position({ value: ' 0 auto auto 0' })}

    &:after {
      content: '';
      display: inline-block;
      border-width: 0.2rem 0.2rem 0 0;
      border-style: solid;
      height: 0.7rem;
      width: 0.7rem;
      transform: rotate(-135deg);
      margin-left: 0.3rem;

      ${({ theme: { BreadcrumbStyle } }) =>
        BreadcrumbStyle &&
        css`
          border-color: ${BreadcrumbStyle?.BdrColors?.BackBtn};
        `}
    }
  }
`;
export const BreadcrumbWrap = styled.div<{
  flexNoPadding?: boolean;
  blurTitle?: boolean;
  isJobDetailsBreadCrumb?: boolean;
}>`
  ${({ blurTitle }) =>
    blurTitle &&
    css`
      .active {
        filter: blur(0.8rem);
      }
      li:nth-last-child(2) {
        position: relative;
        &::after {
          content: '/';
          ${Mixin.Position({ value: '52% auto auto auto ' })}
          position: absolute;
          font-weight: 600;
          transform: translateY(-52%);
          padding: 0 0.7rem 0 0.4rem;
        }
      }
    `};
  ${({ theme: { BreadcrumbStyle } }) =>
    BreadcrumbStyle &&
    css`
      background-color: ${BreadcrumbStyle.BgColors.breadcrumbWrapBg};
    `}
  ${({ flexNoPadding, isJobDetailsBreadCrumb }) =>
    flexNoPadding
      ? css`
          margin: 0 -0.5rem;
          padding: 1.6rem 1.1rem 0;
        `
      : css`
          ${isJobDetailsBreadCrumb
            ? css`
                margin: 0;
                padding: 0;
              `
            : css`
                margin: 0 -1.5rem;
                padding: 1.6rem 1rem 0;
              `}
        `};
`;

export const JobTilesWrap = styled.ul`
  margin: 0;
  padding: 1.2rem;
  list-style: none;
  ${device.mobileSm} {
    padding: 1.2rem 2.1rem 5rem;
  }
`;

export const TileLinks = styled.a`
  display: block;
  padding: 0.8rem 0;
  ${({ theme: { BreadcrumbStyle } }) =>
    BreadcrumbStyle &&
    css`
      border-bottom: 0.1rem solid ${BreadcrumbStyle.BdrColors.tileLinkColor};
    `}
`;

export const SubSidebarWidget = styled.div``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${device.mobileSm} {
    grid-template-columns: 1fr;
  }
`;
export const DropableCategoriesWrapper = styled.div`
  ${device.mobileLg} {
    padding-bottom: 2.8rem;
  }
`;

export const MPRWidgetWrapperLink = styled.a`
  display: block;
  .mpr-widget {
    min-width: 100%;
  }
`;


export const DescriptionText = styled.p`
  text-align: center;
  margin: 0;
  #learn-more {
    cursor: pointer;
    display: inline-block;
  }
  ${device.mobileLg} {
    text-align: left;
  }
`;

export const BtnWrap = styled.div`
  text-align: center;
`;

export const BannerCloseBtn = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  ${({ theme: { CommonStyle } }) =>
    CommonStyle &&
    css`
      font-size: ${CommonStyle.FontSizes.BannerCloseBtn};
      font-family: ${CommonStyle.FontFamily.BannerCloseBtn};
      margin-left: 1rem;
      color: ${CommonStyle.Colors.BannerCloseBtn};

      ${device.mobileSm} {
        margin: 0;
        font-size: ${CommonStyle.FontSizes.BannerCloseBtnSm};
      }
    `}
`;

export const ErrorMsgWrap = styled.span`
  text-align: left;
  display: block;
  margin: 0.5rem 0 0;
  ${({ theme: { FormControlStyle } }) =>
    FormControlStyle &&
    css`
      color: ${FormControlStyle.Colors.ErrorMsgWrap};
      font-size: ${FormControlStyle.FontSizes.ErrorMsgWrap};
      font-weight: ${FormControlStyle.FontWeight.ErrorMsgWrap};
    `}
`;
