import { isRCPortal } from 'helper';

export const BREAD_CRUMBS = [
  {
    page: 'job-full-time-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'job-full-time-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'job-title-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Top Job Titles for Remote & Flexible Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'job-title-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Top Job Titles for Remote & Flexible Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'top-search-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Top Searched Remote Work From Home Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'top-search-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Top Searched Remote Work From Home Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'remote-jobs-public',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: true,
      },
    ],
  },
  {
    page: 'remote-jobs-premium',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: true,
      },
    ],
  },
  {
    page: 'new-jobs-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings for {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'new-jobs-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings for {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'legetimate-jobs-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'New Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'rmc-remote-jobs-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
    ],
  },
  {
    page: 'rmc-remote-jobs-auth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'My Dashboard',
        path: '/dashboard',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
    ],
  },
  {
    page: 'legetimate-jobs-unauth',
    title: 'Remote Jobs - No Ads, Scams, or Junk',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Newest Remote Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'research-companies',
    title: 'Best Companies that Hire for Remote Jobs',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'company-guide-public',
    title: ' Best 100 Companies for Flexible Top 100 Remote Jobs | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'company-guide-premium',
    title: ' Best 100 Companies for Flexible Top 100 Remote Jobs | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'company-detail-premium',
    title: 'Jobs & Careers - Remote Work From Home & Flexible',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-page-public',
    title: 'Telecommuting & Flex-Friendly Companies',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Jobs by Company Name - {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-page-premium',
    title: 'Telecommuting & Flex-Friendly Companies',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Jobs by Company Name - {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-page-alphabet-public',
    title: 'Telecommuting & Flex-Friendly Companies',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Company Name',
        path: '/telecommute-jobs-by-company.aspx',
        static: false,
      },
      {
        name: 'Jobs by Company Name - {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-page-alphabet-premium',
    title: 'Telecommuting & Flex-Friendly Companies',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Company Name',
        path: '/telecommute-jobs-by-company.aspx',
        static: false,
      },
      {
        name: 'Jobs by Company Name - {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'company-detail-public',
    title: 'Jobs & Careers - Remote Work From Home & Flexible',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/company-guide',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-by-location-premium',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-by-location-public',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-featured-auth',
    title: 'Remote and Part-Time Jobs with Featured Employers',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote Jobs with Featured Companies',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-featured-unauth',
    title: 'Remote and Part-Time Jobs with Featured Employers',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote Jobs with Featured Companies',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'surprising-jobs-auth',
    title: 'Remote and Part-Time Jobs with Featured Employers',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Surprising Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'surprising-jobs-unauth',
    title: 'Remote and Part-Time Jobs with Featured Employers',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Surprising Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'research-gig-jobs',
    title: 'An Excellent Guide to Freelance & Gig Job Platforms',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Guide to Freelance Job Platforms',
        path: '',
        static: true,
      },
    ],
  },

  {
    page: 'all-research-gig-jobs',
    title: 'All Freelance Job Platforms',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Guide to Freelance Job Platforms ',
        path: '/job-platforms',
        static: false,
      },
      {
        name: 'All',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'savedJobs',
    title: 'Saved Jobs',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'My Saved Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobsforyou',
    title: 'New Jobs of Interest',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'New Jobs of Interest',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobsApplication',
    title: 'My Job Application',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Job Applications',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'login',
    title: 'My Login Page',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Log In',
        path: '/login',
        static: true,
      },
    ],
  },
  {
    page: 'emp-login',
    title: 'My Login Page',
    children: [
      {
        name: 'Home',
        path: '/employers-post-remote-jobs',
        static: false,
      },
      {
        name: 'Log In',
        path: '/login?emp=1',
        static: true,
      },
    ],
  },
  {
    page: 'forgotpassword',
    title: 'Forgot Password Page',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Password Help',
        path: '/reset-password',
        static: true,
      },
    ],
  },
  {
    page: 'forgotpassword-emp',
    title: 'Forgot Password Page',
    children: [
      {
        name: 'Home',
        path: '/employers-post-remote-jobs',
        static: false,
      },
      {
        name: 'Password Help',
        path: '/reset-password',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-jobs-by-company-public',
    title: 'Find Telecommuting and Part-Time Jobs by Company Name | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'List of Flexible Companies by Letter A-Z',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'telecommute-jobs-by-company-premium',
    title: 'Find Telecommuting and Part-Time Jobs by Company Name | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'List of Flexible Companies by Letter A-Z',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'awards-accolades-page-premium',
    title: 'Award & Accolades',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Awards & Accolades',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'awards-accolades-page-public',
    title: 'Award & Accolades',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Awards & Accolades',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'top-job-categories-public',
    title:
      'The Best List of Companies for Flexible Jobs by Popular Category | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Companies by Popular Category',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'top-job-categories-premium',
    title:
      'The Best List of Companies for Flexible Jobs by Popular Category | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Companies by Popular Category',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'best-companies-by-location-public',
    title:
      'The Best List of Companies for Flexible Jobs by Location | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Companies Guide by Location',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'best-companies-by-location-premium',
    title:
      'The Best List of Companies for Flexible Jobs by Popular Category | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Research Companies',
        path: '/members/companies',
        static: false,
      },
      {
        name: 'Company Guide',
        path: '/company-guide',
        static: false,
      },
      {
        name: 'Companies Guide by Location',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'notFound',
    title: '404 Not Found',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Something has gone wrong',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'saved-searches',
    title: 'Saved Searches',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Saved Searches',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'public-jobs-page',
    title: 'Public Jobs Page',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'public-jobs-page-premium',
    title: 'Public Jobs Page',
    children: [
      {
        name: 'My Dashboard',
        path: '/dashboard',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'public-jobs-feed-page',
    title: 'Public Jobs Page',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Find Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'hosted-jobs-page',
    title: 'Hosted Jobs Page',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'advanced-job-search-public',
    title: 'Advanced Job Search',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Advanced Job Search',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'advanced-job-search-premium',
    title: 'Advanced Job Search',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Advanced Job Search',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-category-page-premium',
    title: '{0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-category-page-public',
    title: 'Remote {0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote {0} - Work From Home',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-page-premium',
    title: '{0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-page-public',
    title: '{0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-page-premium',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: 'Remote Jobs in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-category-loc-page-premium',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: '{0} Jobs',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: '{1}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-page-public',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: 'Remote Jobs in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-category-loc-page-public',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: '{0} Jobs',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: '{1}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-page-city-premium',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: '{1}',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: 'Remote Jobs in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-page-city-public',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location',
        path: '/searchLocation',
        static: false,
      },
      {
        name: '{1}',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: 'Remote Jobs in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-subCategory-page-premium',
    title: '{0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{1}',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-subCategory-page-public',
    title: '{0} - Remote Work From Home & Online | Remote.Co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: '{1}',
        path: '/remote-jobs/{2}',
        static: false,
      },
      {
        name: '{0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'simple-search-public',
    title: 'Job Search Results',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'simple-search-premium',
    title: 'Job Search Results',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Job Listings {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'viewedJobs',
    title: 'Job Search Results',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Viewed Jobs',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'searchLocationCategories-unAuth',
    title: 'Find Remote, Work-from-Home Jobs in Your Field Near You',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },

      {
        name: 'Find Remote, Work-from-Home Jobs in Your Field Near You',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'searchLocationCategories-auth',
    title: 'Find Remote, Work-from-Home Jobs in Your Field Near You',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Find Remote, Work-from-Home Jobs in Your Field Near You',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-categories-premium',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'My Dashboard',
        path: isRCPortal ? '/dashboard' : '/members',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location Category',
        path: '/searchLocation/searchCategory',
        static: false,
      },
      {
        name: 'Remote Jobs by Categories in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'jobs-location-categories',
    title: 'Flexible & Remote Jobs Near Me or Anywhere in the World',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Jobs by Location Category',
        path: '/searchLocation/searchCategory',
        static: false,
      },
      {
        name: 'Remote Jobs by Categories in {0}',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'category-rc-public',
    title: 'Remote {0} Jobs Online - Work From Home | Remote.co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote {0} Jobs – Work From Home',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'category-rc-premium',
    title: 'Remote {0} Jobs Online - Work From Home | Remote.co',
    children: [
      {
        name: 'My Dashboard',
        path: '/dashboard',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote {0} Jobs – Work From Home',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'job-filter-rc-public',
    title: 'Remote {0} Jobs Online - Work From Home | Remote.co',
    children: [
      {
        name: 'Home',
        path: '/',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote {0} Jobs - Work From Home',
        path: '',
        static: true,
      },
    ],
  },
  {
    page: 'job-filter-rc-premium',
    title: 'Remote {0} Jobs Online - Work From Home | Remote.co',
    children: [
      {
        name: 'My Dashboard',
        path: '/dashboard',
        static: false,
      },
      {
        name: 'Remote Jobs',
        path: '/remote-jobs',
        static: false,
      },
      {
        name: 'Remote {0} Jobs - Work From Home',
        path: '',
        static: true,
      },
    ],
  },
];
